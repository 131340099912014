
import React from "react"
import ImgInvitatie from "./ImgInvitatie";
import mikey from '../images/lion2.png'
import "../css/ImgInvitatie.css"
import background from "../images/2.jpg"

const CardInvitatie = () => {
    return (

        <section id="invitatie" className="text-center mt-5" >

            <div className="card card-invitation text-center col-lg-6 col-sm-10  m-auto mb-4" >
                <div className="card p-0 overflow-hidden h-100 shadow" style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>


                    <div class="card-body">
                        
                            <h1 style={{ color: 'red' }}>Invitație</h1>
                            <h5 className="animate__animated animate__pulse animate__delay-4s animate__infinite">
                                <svg width="1.5em" height="1.5em" color="red" viewBox="0 0 16 16" class="bi bi-suit-heart-fill"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                                </svg>
                                <br />

                            </h5>
                            <h1 style={{ fontSize: window.innerWidth >= 500 ? '30px' : '20px', color: 'green', }}>
                                Ursuleții cei mai mici, <br />
                                Împreună cu surioara Simonica și părinții noștri, <br />
                                Vă invităm la cea mai așteptată Petrecere a Urșilor! <br />
                                Vă așteptăm, cu mic și mare, <br />
                                Cu mult drag și încântare!<br />
                                Cu muzică și voie bună,<br />
                                Ne vom distra, de minune!
                            </h1>








                      

                       
                        <div id="gol"></div>








                        <h4 class="card-footer" style={{ color: 'green' }}></h4>

                        <a href="#forms" className="btn btn-primary">Confirmați Prezența</a>

                    </div>




                </div>




            </div>


        </section>

    )
}

export default CardInvitatie;