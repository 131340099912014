import restaurant from "./images/vila_garden2.jpg";
import biserica from "./images/biserica.jpg";
import img_card from "./images/cu.jpg";
import img_card_nasi from "./images/ursu.png";
import imgheadermb from "./images/fm.jpg";
import imgheader from "./images/fm1.jpg";
import imgheadermiini from "./images/wb.jpg";
import imgheadermiinimb from "./images/wb1.jpg";
import logo from './images/w7.png'



const data = {
    introData: [{
        copilul: "Casandra și Nicolae",
        familia: "familia Ursu",
        logo: logo,
        tata: "Sergiu",
        mama: "Lucia",
        data: "",
        data_confirmare: "15 iulie 2023",
        savedata: "",
        savedata1: "",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "lucia.ursu05@gmail.com", 
       tel: "+37369484958",
       phone: "tel:+37369484958",
       viber: "viber://chat?number=%2B37369484958",
       whatsapp: "https://wa.me/+37369484958",
       messenger: "https://www.messenger.com/t/lucia.corobca",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Villa Garden",
            data: "28 iulie 2023, vineri, ora 17:30",
            adress: "Bulevardul Decebal 16, Chişinău",
            harta: "https://goo.gl/maps/G9i7UfVgRsfubLqp7",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10883.83314733969!2d28.8583998!3d47.001792!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c02f96bfbc9%3A0x83fa69ace0855818!2sVilla%20Garden!5e0!3m2!1sro!2s!4v1687338431875!5m2!1sro!2s" 
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Salutare",
          
            message: "Pentru că ne este dor de voi,  ",
            message1:'Pentru că s-au adunat mai multe motive, ',
            message2:"Demne de o MEGA Sărbătoare,",
            message3:'Am zis că....Hai s-o facem și pe asta: ',
            message4:"Petrecerea Urșilor!!!",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img_card_nasi,
            title1: "Invitație",
            title2: "Cumetria Dxxx",
            title3: "Aniversarea și Cumetria Sxxx",
            message: "Pentru că ne este dor de voi 🙂    ",
            message1:'Pentru că s-au adunat mai multe motive demne de o MEGA Sărbătoare,',
            message2:"Am zis că... HAI s'o facem și pe asta - Perecerea URȘILOR!🙂 ",

        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title1: "„Copilăria este inima tuturor vârstelor.” - Lucian Blaga",
            title2: "",
            title3: "",
            message: "",  
        }
    ],

}

export default data;