import React, { Component } from "react";
import '../css/Header.css'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';


export default class Header extends Component {
    constructor () {
        super();
        this.state={
           swow: true,
        };

    }
    render () {
        return (
        
<nav class="navbar navbar-expand-lg navbar-dark fixed-top">
  <div className="container-fluid px-5">
  <img id="logo" src={this.props.logo}/>
    <a className="navbar-brand" id="logo-name" href="#">
      {this.props.familia}</a>
    <button className="navbar-toggler border text-dark border-dark" 
    onClick={()=>{this.setState({show: !this.state.show})}}>
      {this.state.show ?  <CloseIcon/> : <MenuIcon/>}
    </button>
    
    <div className={this.state.show ? 'collapse navbar-collapse  active bg-white text-center'  : 'collapse navbar-collapse text-center'}>
      <ul className="navbar-nav ms-auto">
        <li className="nav-item">
          <a className="nav-link text-dark"  href="#blog">{this.props.copilul}</a>
        </li>
       {/**<li className="nav-item">
          <a className="nav-link text-dark"  href="#gallery">Gallery</a>
        </li> */} 
        <li className="nav-item">
          <a className="nav-link text-dark"  href="#invitatie">Invitația</a>
        </li>
        <li className="nav-item">
          <a className="nav-link text-dark" href="#geolocal">Unde și Când?</a>
        </li>
        <li className="nav-item">
          <a className="nav-link text-dark" href="#forms">Confirmare</a>
        </li>
      </ul>
    </div>
  </div>
</nav> 
     
        )

 }}